




































import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { IFilterComp } from "ag-grid-community";
import { Condition } from "@/providers/filter";

@Component({
  name: "grid-list-filter-component",
  components: { AgGridVue },
})
export default class GridListFilterComponent extends Vue implements IFilterComp {
  values = new Array<any>();
  selectedValues: string[] = [];
  debounceTimer: any;
  search = "";
  async beforeMount() {
    if (this.$data.params.values instanceof Function) {
      this.values = await this.$data.params.values();
    } else {
      this.values = this.$data.params.values;
    }
    this.values.push({ key: "null,null", value: "Aucune valeur" });
    for (const key in this.values.keys) {
      this.selectedValues.push(key);
    }
  }

  get selectAll(): boolean {
    return this.selectedValues.length == this.values.length;
  }

  set selectAll(value: boolean) {
    this.selectedValues = value ? this.values.map((a) => a.key) : [];
    this.$data.params.filterChangedCallback();
  }

  updateFilter() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    if (this.selectedValues.length == this.values.length) {
      this.$data.params.filterChangedCallback();
    } else {
      this.debounceTimer = setTimeout(() => this.$data.params.filterChangedCallback(), 500);
    }
  }

  init(params: any) {
    throw new Error("Method not implemented.");
  }

  getGui(): HTMLElement {
    throw new Error("Method not implemented.");
  }

  isFilterActive() {
    return this.selectedValues.length > 0;
  }

  doesFilterPass(params: any) {
    return this.selectedValues.includes(this.$data.params.valueGetter(params.node));
  }

  getModel() {
    if (this.isFilterActive()) {
      return Condition.whereIn(this.selectedValues);
    } else {
      return null;
    }
  }

  setModel(model: any) {
    this.selectedValues = model ? model.filter : [];
  }

  updateSearch() {
    this.values = this.values.map((v) => {
      return {
        ...v,
        hide: !(this.search == "" || v.value.toLowerCase().includes(this.search.toLowerCase())),
      };
    });
  }
}
