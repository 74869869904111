


























































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import AppExpansionPanelHeader from "@/components/_shared/panels/app-expansion-panel-header.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { productApiProvider } from "@/providers/product-api-provider";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import WarehouseModal from "@/components/order/warehouse-modal.vue";
import { Product } from "@/interfaces/product";
import OrderProductGrid from "@/components/order/order-product-grid.vue";
import OrderSelectedStockGrid from "@/components/order/order-selected-stock-grid.vue";
import ProductDetailModal from "@/components/_shared/modals/product-detail-modal.vue";
import { DataSource } from "@/utils/grid/datasource";
import { Order, OrderStatus } from "@/interfaces/order";
import { orderApiProvider } from "@/providers/order-api-provider";
import { Warehouse } from "@/interfaces/warehouse";
import ContactSearchComponent from "@/components/_shared/searchs/contact-search-component.vue";
import router from "@/router/router";
import { Stock } from "@/interfaces/stock";
import store from "../../stores/store";
import { formValidation } from "@/utils/form-validation-helper";
import { Contact, ContactType } from "@/interfaces/contact";
import { Address } from "@/interfaces/address";
import StockOrderModal, { StockWithQuantity } from "@/components/order/stock-order-modal.vue";
import { contactApiProvider } from "@/providers/contact-api-provider";
import DatePickerFormComponent from "@/components/_shared/forms/date-picker-form-component.vue";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { UserRole } from "@/interfaces/user";
import { Company } from "@/interfaces/company";
import { companyApiProvider } from "@/providers/company-api-provider";
import { Filter, QueryBuilder } from "@/providers/filter";
import ServiceFormComponent from "@/components/_shared/forms/service-form-component.vue";
import ServiceSelectorComponent from "@/components/_shared/others/service-selector-component.vue";
import { Segment } from "@/interfaces/segment";

@Component({
  name: "create-order-page",
  components: {
    ServiceSelectorComponent,
    AppExpansionPanelHeader,
    AppIconTextButton,
    OrderProductGrid,
    OrderSelectedStockGrid,
    ContactSearchComponent,
    AddressFormComponent,
    WarehouseModal,
    StockOrderModal,
    ProductDetailModal,
    DatePickerFormComponent,
    ServiceFormComponent,
  },
})
export default class CreateOrderPage extends Vue {
  datasource: DataSource;

  rules = formValidation.getRules();

  private currentStep = 1;
  private formSending = false;
  private isLoading = true;
  private company: Company = store.getters["user/getUser"].company;
  private typeSelect = ["ABO", "COF", "SHOP"];

  private get isModifyMode() {
    return this.$route.params.order_id != undefined && this.order.statusCode != OrderStatus.draft;
  }

  superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;

  order: Order = new Order();

  companySelect: Company[] = [];

  $refs: {
    productGrid: HTMLFormElement;
    cartGrid: HTMLFormElement;
    addressForm: HTMLFormElement;
    warehouseModal: HTMLFormElement;
    stockModal: HTMLFormElement;
    productDetailModal: HTMLFormElement;
    orderForm: HTMLFormElement;
    customerSearch: HTMLFormElement; // Champ recherche du client.
    carrierSearch: HTMLFormElement; // Champ de recherche du transporteur.
  };

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("order.list_page_title"), href: "/order/list" },
    { text: this.$t("order.create_page_title") },
  ];

  /**
   * This methods was called when the page is open in modification mode.
   */
  modifyPageConfiguration(order: Order) {
    const title = this.$t("order.modify_page_title", { code: order.reference });
    this.breadcrumbs = [
      { text: this.$t("app.title") },
      { text: "Suivi des commandes", href: "/order/list" },
      { text: "Modifier une commande" },
    ];
    this.$eventHub.$emit("update-title", title);
  }

  /**
   * On pickup managed by Dartess change
   * If pickupManagedByDartess, no shipping label required
   * @param value
   */
  async onPickupManagedByDartessChange(value: number) {
    this.order.shippingLabelRequired = value ? 0 : 1;
    this.order.carrier = null;
  }

  /**
   * On company change
   * @param value
   */
  async onCompanyChange(value: Company) {
    const response = await companyApiProvider.getCompany(value.id);
    if (response) {
      this.order.owner = response;
    }
  }

  async beforeMount() {
    // On récupère l'utilisateur (si les segments ont changés...)
    await store.dispatch("user/fetchUser");

    this.$route.meta.title = "Nouvelle commande";

    if (this.superAdmin) {
      this.companySelect = await companyApiProvider.getCompanies(true);
    } else {
      const user = store.getters["user/getUser"];
      await this.onCompanyChange(user.company);
    }

    // Initialisation des données.
    this.datasource = DataSource.from(productApiProvider).withFilters(async () => {
      const filters = [];

      // Remove hidden products
      filters.push(Filter.whereEquals("hidden", false));

      if (this.order && this.order.owner) {
        // Si le client n'est pas en mutualisée.
        if (this.order.segment && !this.order.owner.isPooledActivity) {
          filters.push(Filter.whereEquals("stocks.segment", [this.order.segment.code, "NULL"]));
        }
        if (this.order.owner) {
          filters.push(Filter.whereEquals("owner", this.order.owner.id));
        }
      }
      return filters;
    });

    /** Edition mode ----------------------------- */
    if (this.$route.params.order_id) {
      // Can I modify this order ?
      const response = await orderApiProvider.getOrder(this.$route.params.order_id);
      this.order = response;

      // Retrieve carrier and service already set
      const carrier = await orderApiProvider.getCarrier(this.$route.params.order_id);
      const service = await orderApiProvider.getService(this.$route.params.order_id);
      this.order.carrier = carrier;
      this.order.service = service;
      if (response && this.order.canModifyScope()) {
        this.modifyPageConfiguration(response);
      } else {
        await router.replace("/order/list");
      }

      // Default pickupManagedByDartess
      this.setPickupManagedByDartess(this.order.segment);

      /** Creation mode ----------------------------- */
    } else {
      // If an order is currently in creation we get it.
      if (store.getters["createOrder/getCurrentOrderId"]) {
        const response = await orderApiProvider.getOrder(
          store.getters["createOrder/getCurrentOrderId"]
        );
        if (response) {
          this.order = response;
        }
        // Is a new order.
      }

      // Si le client est mono segment ou avec des activités mutualisées.
      if (this.company.segments.length == 1) {
        this.order.segment = this.company.segments[0];
      }
    }

    // On a ajouté un transporteur.
    if (this.$route.query.carrier_id) {
      this.order.carrier = await contactApiProvider.getContact(
        this.$route.query.carrier_id as string
      );
    }

    // On a ajouté un destinataire.
    if (this.$route.query.recipient_id) {
      this.order.customer = await contactApiProvider.getContact(
        this.$route.query.recipient_id as string
      );
      if (this.order.customer) {
        this.onCustomerAdd(this.order.customer);
      }
    }

    // Gestion de l'affichage.
    this.setCurrentStep();
    this.isLoading = false;
  }

  /**
   * Cette fonction détermine l'étape actuel à afficher lors du chargement de la page.
   */
  setCurrentStep() {
    if (this.order.carrier || this.order.customer) {
      this.currentStep = 3;
    } else if (this.order && this.order.owner && this.order.segment) {
      this.currentStep = 2;
    }
    this.$forceUpdate();
  }

  async addStockToCart(stock: Stock, quantity: number) {
    this.showLoadingOverlay();
    const stockInCart = this.order.stocks.find((s) => s.id == stock.id);
    if (stockInCart != null) {
      stockInCart.ordered = quantity;
    } else {
      stock.ordered = quantity;
      this.order.stocks.push(stock);
    }
    // We create the order when we add an item in.
    // On mets à jour la commande existante.
    if (this.order.id) {
      const response = await orderApiProvider.updateOrder(this.order);
      if (response) {
        this.order = response;
        this.refreshCart();
      }
    } else {
      // On crée une nouvelle commande.
      this.order.statusCode = OrderStatus.draft;
      this.order.warehouse = stock.warehouse;
      this.order.recipient = null;
      const response = await orderApiProvider.createOrder(this.order);
      if (response) {
        this.order = response;
        await store.commit("createOrder/setCurrentOrderId", response.id);
        this.refreshCart();
      }
    }
    this.hideOverlay();
  }

  // Delete a stock of the cart and update the draft order in API.
  async deleteStockFromCart(stock: Stock) {
    this.showLoadingOverlay();
    const stockTemp = stock;
    const index = this.order.stocks.findIndex((s: Stock) => stock.id == s.id);
    this.order.stocks.splice(index, 1);
    const response = await orderApiProvider.updateOrder(this.order);
    if (response) {
      this.order = response;
      this.refreshCart();
      this.updateExpected(0, stockTemp.product.id, stockTemp.id);
    }
    this.hideOverlay();
  }

  // Change the ordered value for a product's stock.
  updateExpected(ordered: number, productId: string, stockId: string) {
    const node = this.$refs.productGrid.gridOptions.api.getRowNode(productId);
    if (node) {
      const productStockIndex = node?.data.stocks.findIndex((s: Stock) => stockId == s.id);
      node.data.stocks[productStockIndex].ordered = 0;
      this.$refs.productGrid.gridOptions.api.redrawRows({ rowNodes: [node] });
    }
  }

  stepOneValidate() {
    if (this.currentStep == 1 && this.order.segment) {
      this.order.stocks = [];
      this.$refs.productGrid.onFilterChange();
      this.currentStep = 2;
    }
  }

  // Checking if the current step is 2 and if the order.stocks array has a length greater than 0. If
  // both of these conditions are true, then the current step is set to 3.
  stepTwoValidate() {
    if (this.currentStep == 2 && this.order.stocks.length > 0) {
      this.currentStep = 3;
    }
  }

  setPickupManagedByDartess(segment: Segment) {
    this.order.pickupManagedByDartess = segment.code === "B2B" ? 1 : 0;
  }

  async onWarehouseModalComplete(warehouse: Warehouse) {
    this.order.warehouse = warehouse;
    await this.validateOrder(true);
  }

  async onStockModalComplete(stocks: Array<StockWithQuantity>, product: Product) {
    for (const value of stocks) {
      if (value.quantity > 0) {
        await this.addStockToCart(value.stock, value.quantity);
      }
    }
    const node = this.$refs.productGrid.gridOptions.api.getRowNode(product.id.toString());
    node.setDataValue("quantity", null);
  }

  onCustomerAdd(customer: Contact) {
    if (customer) {
      this.order.recipient = new Address({ ...customer.address });
    }
  }

  getServicesByCarrier(selectedCarrier: Contact) {
    let carriers = [];
    if (selectedCarrier) {
      carriers = this.order.owner.carriers.filter(
        (carrier) => carrier.carrier.code === selectedCarrier.code
      );
    }
    return carriers;
  }

  validateOrder(validated = false) {
    let isValid = true;
    if (validated) {
      this.order.statusCode = OrderStatus.transmitting;
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.success,
        mainText: this.$t("order.create_validate_success_msg"),
      });
      isValid = this.$refs.orderForm.validate();
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.success,
        mainText: this.$t("order.create_draft_success_msg"),
      });
      this.order.statusCode = OrderStatus.draft;
    }
    if (isValid) {
      this.formSending = true;
      orderApiProvider.updateOrder(this.order);
      store.dispatch("createOrder/reset");
      router.push("/order/list");
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }

  async computeWarehouse(e) {
    e.preventDefault();
    // Compute origin warehouses of all product
    const originWarehouses = new Map<string, Warehouse>();
    this.order.stocks.forEach((stock: Stock) => {
      if (stock.warehouse && !originWarehouses.has(stock.warehouse.id)) {
        originWarehouses.set(stock.warehouse.id, stock.warehouse);
      }
    });
    // If more than one origin, ask for grouping warehouse
    if (originWarehouses.size > 1) {
      this.$refs.warehouseModal.items = Array.from(originWarehouses.values());
      this.$refs.warehouseModal.toggleModal(true);
    } else if (originWarehouses.size == 1) {
      this.order.warehouse = originWarehouses.values().next().value;
      await this.validateOrder(true);
    } else {
      console.error(`No warehouse available for order ${this.order.code}`);
    }
  }

  refreshCart() {
    this.$refs.cartGrid.gridOptions.api.redrawRows();
  }

  showLoadingOverlay() {
    this.$refs.productGrid.gridOptions.api.showLoadingOverlay();
  }

  hideOverlay() {
    this.$refs.productGrid.gridOptions.api.hideOverlay();
  }

  /*
    This method is called when we click on 'add to cart' button.
     */
  async onAddToCart(product: Product) {
    if (product.quantity > 0) {
      // Link product to stock that will now be standalone
      product.stocks.forEach((stock: Stock) => (stock.product = product));
      if (product.stocks.length > 1) {
        this.$refs.stockModal.setValues(product, product.stocks, product.quantity);
        this.$refs.stockModal.toggleModal(true);
      } else {
        this.addStockToCart(product.stocks[0], product.quantity);
        const node = this.$refs.productGrid.gridOptions.api.getRowNode(product.id.toString());
        node.setDataValue("quantity", null);
        this.refreshCart();
      }
    } else {
      product.stocks.forEach((stock) => this.deleteStockFromCart(stock));
      const node = this.$refs.productGrid.gridOptions.api.getRowNode(product.id.toString());
      node.setDataValue("quantity", null);
      this.refreshCart();
    }
  }

  async onClickNewRecipient() {
    await this.newContact(ContactType.recipient);
  }

  async onClickNewCarrier() {
    await this.newContact(ContactType.carrier);
  }

  onChangeCarrier() {
    if (this.order.recipient) {
      this.order.recipient.pickupCode = null;
    }
    this.order.type = null;
  }

  // On click on new contact.
  private async newContact(type: ContactType) {
    if (this.order.warehouse) {
      // Save the current state of the screen.
      const response = await orderApiProvider.updateOrder(this.order);
      store.commit("createOrder/setCurrentOrderId", response.id);
      if (response) {
        // A router.replace() method that is used to replace the current route with a new route.
        await this.$router.replace({
          name: `create-${type}`,
          params: {
            origin: this.$route.path ?? "",
            warehouseId: this.order.warehouse.id,
            type,
            segmentId: this.order.segment.id,
            // Setting the ownerId to the id of the owner of the order.
            ownerId: this.order.owner ? this.order.owner.id : null,
          },
        });
      }
    }
  }

  mounted() {
    this.$eventHub.$on("add-product-to-cart", (data: Product) => this.onAddToCart(data));
    this.$eventHub.$on("remove-stock-from-cart", (data: Stock) => this.deleteStockFromCart(data));
    this.$eventHub.$emit("toggle-title-divider", false);
  }

  beforeDestroy() {
    this.$eventHub.$off("add-product-to-cart");
    this.$eventHub.$off("remove-stock-from-cart");
    this.$eventHub.$emit("toggle-title-divider", true);
  }
}
